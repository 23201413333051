import { useEffect } from "react";
import { useInitializeRecaptcha } from "common";
import "./App.css";

const App = () => {
  useInitializeRecaptcha();

  useEffect(() => {
    window.location.href = "https://www.newm.io/music";
  }, []);

  return <div style={ { display: "flex", backgroundColor: "blacK" } } />;
};

export default App;
